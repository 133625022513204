<template lang="html">
  <section>
    <audio id="snd" ref="sound-sad" src="../assets/beep-sad.wav" />
    <audio ref="sound-happy" src="../assets/beep-happy.wav" />

    <section v-show="stage === 'front'" class="front">
      <h1>
        {{ $t('Tyre hotel scanner') }}
      </h1>
      <div class="x-block">
        <span v-show="isFrontStatusNone">
          {{ $t('Scan a tyre hotel label and change its information') }}
        </span>
        <span v-show="isFrontStatusHotelSaved" class="front-status-update">
          {{ $t('The tyre hotel info was saved') }}
        </span>
        <span v-show="isFrontStatusHotelCancelled" class="front-status-cancel">
          {{ $t('The tyre hotel info was not saved') }}
        </span>
        <span v-show="isFrontStatusScanCancelled" class="front-status-cancel">
          {{ $t('Scanning was cancelled') }}
        </span>
      </div>
      <div v-show="readyState === 2" class="x-block error-message">
        {{ $t('Camera not available') }}
      </div>
      <div class="x-block">
        <v-btn :disabled="readyState !== 1" color="primary" x-large @click="start('hotel')">
          <div v-show="readyState === 0">
            <v-icon>fa-spin fa-spinner</v-icon>
            {{ $t('Waiting for camera') }}
          </div>
          <div v-show="readyState === 1">
            <span v-show="isFrontStatusNone">
              {{ $t('Start scanning') }}
            </span>
            <span v-show="!isFrontStatusNone">
              {{ $t('Scan another') }}
            </span>
          </div>
        </v-btn>
      </div>
      <div class="x-block">
        <v-btn :to="appendSitePrefix('/')" x-large>
          <span v-show="isFrontStatusNone">
            {{ $t('Back') }}
          </span>
          <span v-show="!isFrontStatusNone">
            {{ $t('Finished scanning') }}
          </span>
        </v-btn>
      </div>
    </section>

    <qrcode-reader
      v-show="stage === 'scan'"
      :active="scanner.active"
      :finish="scanner.finished"
      :readers="scanner.readers"
      @ready="onReady"
      @decode="onDecode"
    >
      <div class="overlay-contents">
        <div class="overlay-info">
          {{ expect === 'hotel' ? $t('Scan a tyre hotel label') : $t('Scan a position label') }}
        </div>
        <div class="overlay-error">
          <i v-show="isLoading" class="fa fa-spin fa-spinner" />
          {{ error }}
        </div>
        <div class="overlay-actions">
          <v-btn large @click="cancelScanning()">
            {{ $t('Cancel') }}
          </v-btn>
          <!-- if this.expect==position give option to search position manually
					<button class="btn btn-secondary" @click="searchPosition()">{{ $t('Search') }}</button>
					-->
        </div>
      </div>
    </qrcode-reader>

    <div v-show="stage === 'hotel'" class="hotel">
      <h1>
        {{ hotel.licenseplate }}
      </h1>

      <v-layout>
        <v-row>
          <v-col cols="6">
            <div class="x-label">
              {{ $t('Position') }}
            </div>
            <div class="x-value">
              {{ hotel.position ? hotel.position.name : '' }}
              <span v-show="hotel.position" class="x-location">
                ({{ hotel.position ? hotel.position.location_code : '' }})
              </span>
            </div>
            <div>
              <v-btn color="primary" @click="start('position')">
                {{ $t('Change') }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6" align="end">
            <div class="x-label">
              {{ $t('Season in stock') }}
            </div>
            <div class="x-value">
              {{ $t(seasonLabel) }}
            </div>
            <div>
              <v-btn color="primary" @click="switchSeasonInStock">
                {{ $t('Switch') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-layout>

      <v-tabs v-model="tab" prominent centered grow background-color="blue-grey lighten-4" color="black" icons-and-text>
        <v-tab key="summer">
          {{ $t('Summer') }}
          <v-icon small>
            {{ summerStatusIcon }}
          </v-icon>
        </v-tab>
        <v-tab key="winter">
          {{ $t('Winter') }}
          <v-icon small>
            {{ winterStatusIcon }}
          </v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="summer">
          <tyre-hotel-lines :lines="summerLines" :season="hotel.season" />
        </v-tab-item>
        <v-tab-item key="winter">
          <tyre-hotel-lines :lines="winterLines" :season="hotel.season" />
        </v-tab-item>
      </v-tabs-items>

      <div class="footer">
        <v-layout>
          <v-row justify="center">
            <v-col cols="6">
              <v-btn color="primary" :disabled="isLoading" @click="save()">
                <i v-show="isLoading" class="fa fa-spin fa-spinner" />
                {{ $t('Save') }}
              </v-btn>
            </v-col>
            <v-col cols="6" align="end">
              <v-btn :disabled="isLoading" @click="cancel()">
                {{ $t('Cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-layout>
      </div>
    </div>
  </section>
</template>

<script>
  import { parseBarCode } from './../utils/barCodeParser'
  import TyreHotelLines from './../components/tyre-hotel-inventory/TyreHotelLines'
  import QrcodeReader from '../components/QrcodeReader'
  import { appendSitePrefix, isCurrentSiteId } from '@/utils/routeUtils'

  const FrontStatusNone = 0
  const FrontStatusHotelSaved = 1
  const FrontStatusHotelCancelled = 2
  const FrontStatusScanCancelled = 3

  export default {
    name: 'InventoryTyrehotel2',
    components: {
      QrcodeReader,
      TyreHotelLines,
    },
    data() {
      return {
        scanner: {
          readers: ['qr'],
          active: false,
          finish: false,
          testMode: true,
        },
        error: '',
        expect: null,
        tab: '',
        readyState: 0,
        stage: 'front',
        isLoading: false,
        saveStatus: FrontStatusNone,
        hotel: {},
      }
    },
    computed: {
      isFrontStatusHotelSaved() {
        return this.saveStatus === FrontStatusHotelSaved
      },
      isFrontStatusHotelCancelled() {
        return this.saveStatus === FrontStatusHotelCancelled
      },
      isFrontStatusScanCancelled() {
        return this.saveStatus === FrontStatusScanCancelled
      },
      isFrontStatusNone() {
        return this.saveStatus === FrontStatusNone
      },
      seasonLabel() {
        const s = (this.hotel ? this.hotel.season : '') || ''
        return s.charAt(0).toUpperCase() + s.slice(1)
      },
      summerLines() {
        const result = []
        if (this.hotel && this.hotel.lines) {
          for (let i = 0; i < this.hotel.lines.length; i++) {
            if (this.hotel.lines[i].season === 1) {
              result[result.length] = this.hotel.lines[i]
            }
          }
        }
        return result
      },
      winterLines() {
        const result = []
        if (this.hotel && this.hotel.lines) {
          for (let i = 0; i < this.hotel.lines.length; i++) {
            if (this.hotel.lines[i].season === 2) {
              result[result.length] = this.hotel.lines[i]
            }
          }
        }
        return result
      },
      summerStatusIcon() {
        let stock = 0
        const season = 1
        if (this.hotel.lines) {
          this.hotel.lines.forEach((line) => {
            if (line.season === season) {
              stock += line.inStock ? 1 : 0
            }
          })
        }
        return stock > 0 ? 'fa-warehouse-alt' : 'fa-car'
      },
      winterStatusIcon() {
        let stock = 0
        const season = 2
        if (this.hotel.lines) {
          this.hotel.lines.forEach((line) => {
            if (line.season === season) {
              stock += line.inStock ? 1 : 0
            }
          })
        }
        return stock > 0 ? 'fa-warehouse-alt' : 'fa-car'
      },
    },
    methods: {
      appendSitePrefix(url) {
        return appendSitePrefix(url)
      },

      start(expect) {
        this.saveStatus = FrontStatusNone
        this.expect = expect
        this.$refs['sound-sad'].load()
        this.$refs['sound-happy'].load()
        this.scanner.active = true
        this.stage = 'scan'
        this.error = ''
      },

      pause() {
        this.scanner.active = false
      },

      // Called by cancel button in hotel view
      cancel() {
        this.stage = 'front'
        this.scanner.active = false
        this.saveStatus = FrontStatusHotelCancelled
      },

      // Called by cancel button in scan view
      cancelScanning() {
        if (this.expect === 'hotel') {
          this.stage = 'front'
          this.saveStatus = FrontStatusScanCancelled
        } else {
          this.stage = 'hotel'
          this.saveStatus = FrontStatusHotelCancelled
        }
        this.scanner.active = false
      },

      /*
			searchPosition () {
			},
			*/

      save() {
        this.isLoading = true

        const lines = []
        const seasons = { summer: 1, winter: 2 }
        const seasonInStock = seasons[this.hotel.season]
        for (let i = 0; i < this.hotel.lines.length; i++) {
          lines[lines.length] = {
            _id: this.hotel.lines[i]._id,
            position:
              this.hotel.lines[i].season === seasonInStock && this.hotel.lines[i].inStock
                ? this.hotel.position._id
                : null,
            mm: this.hotel.lines[i].mm,
          }
        }
        console.log('save.lines:', lines)

        const data = {
          lines: lines,
          season: seasonInStock,
        }

        this.$axios
          .post('/v3/stock/tyre-hotel/' + this.hotel._id, data)
          .then((response) => {
            this.isLoading = false
            this.stage = 'front'
            this.saveStatus = FrontStatusHotelSaved
            this.hotel = {}
          })
          .catch((err) => {
            this.isLoading = false
            console.error('save error')
          })
      },

      switchSeasonInStock() {
        this.hotel.season = this.hotel.season === 'summer' ? 'winter' : 'summer'
        const seasons = { summer: 1, winter: 2 }
        const season = seasons[this.hotel.season]
        for (let i = 0; i < this.hotel.lines.length; i++) {
          const line = this.hotel.lines[i]
          const isSeason = season === line.season
          line.inStock = isSeason
        }
        this.setTab(this.hotel.season)
      },

      setTab(season) {
        this.tab = season === 'summer' ? 0 : 1
      },

      setScanOk(message) {
        this.$refs['sound-happy'].play()
        this.error = ''
      },
      setScanError(message, err) {
        this.$refs['sound-sad'].play()
        this.error = message
        // this.scannedItem = message
        // this.scannedItemClass = 'error'
        console.error('setScanError:', message, err)
      },
      setScanReset() {
        this.error = ''
        // this.scannedItem = ''
        // this.scannedItemClass = ''
      },

      onReady(err) {
        this.readyState = err ? 2 : 1
      },

      onDecode(content) {
        this.setScanReset()
        const barcode = parseBarCode(content) || {}
        if (this.expect === 'hotel' && (barcode.type === 'tyre-hotel-line' || barcode.type === 'tyre-hotel-wheel')) {
          if (barcode.type === 'tyre-hotel-line') {
            this.onTyreHotelLine(barcode.id)
          } else if (barcode.type === 'tyre-hotel-wheel') {
            this.onTyreHotelWheel(barcode.id)
          }
        } else if (this.expect === 'position' && barcode.type === 'stock-position') {
          this.onPosition(barcode.id)
        } else {
          this.setScanError('Unknown barcode')
        }
      },

      onPosition(id) {
        // console.log('position:', id)
        this.isLoading = true
        this.$axios
          .get('/v3/stock/position/' + id)
          .then((response) => {
            this.setScanOk()
            this.isLoading = false
            this.stage = 'hotel'
            this.hotel.position = response.data.data // tuba todo
            this.pause()
          })
          .catch((err) => {
            this.setScanError('Unknown position scanned', err)
            this.isLoading = false
          })
      },

      onTyreHotelLine(id) {
        this.isLoading = true
        this.$axios
          .get('/v3/stock/tyre-hotel-from-line/' + id)
          .then((response) => {
            this.setScanOk()
            this.isLoading = false
            this.stage = 'hotel'
            const hotel = response.data.data
            for (let i = 0; i < hotel.lines.length; i++) {
              hotel.lines[i].inStock = !!(hotel.lines[i].position && hotel.lines[i].position._id)
            }
            this.hotel = hotel
            this.setTab(hotel.season)
            this.pause()
          })
          .catch((err) => {
            this.setScanError('Unknown tyre hotel item scanned', err)
            this.isLoading = false
          })
      },

      onTyreHotelWheel(id) {
        this.isLoading = true
        this.$axios
          .get('/v3/stock/tyre-hotel-from-wheel/' + id)
          .then((response) => {
            this.setScanOk()
            this.isLoading = false
            this.stage = 'hotel'
            const hotel = response.data.data
            for (let i = 0; i < hotel.lines.length; i++) {
              hotel.lines[i].inStock = !!(hotel.lines[i].position && hotel.lines[i].position._id)
            }
            this.hotel = hotel
            this.setTab(hotel.season)
            this.pause()
          })
          .catch((errorResponse) => {
            const message = errorResponse.response.data.errors.map((error) => error.message).join(', ')
            this.setScanError(message ?? 'Unknown error', errorResponse)
            this.isLoading = false
          })
      },
    },
  }
</script>

<style lang="sass" scoped>

  section
  	padding-bottom: 20px

  .front, .hotel
  	max-width: 600px
  	margin-left: auto
  	margin-right: auto
  	padding-left: 10px
  	padding-right: 10px

  .footer
  	position: fixed
  	bottom: 0
  	left: 0
  	right: 0
  	height: 60px
  	background: white
  	box-shadow: 0px -2px 15px -3px rgba(0, 0, 0, 0.5)
  	padding-left: 10px
  	padding-right: 10px
  	max-width: 600px
  	margin-left: auto
  	margin-right: auto
  	.v-btn
  		width: 100px

  .front
  	text-align: center
  	.x-block
  		margin-bottom: 30px
  	.front-status-update
  		color: green
  		font-weight: bold

  	.front-status-cancel
  		color: #FC8100
  		font-weight: bold

  .overlay-contents
  	color: white
  	text-align: center
  	font-size: 20px
  	padding: 10px 0px
  	.overlay-info
  		line-height: 30px
  		height: 30px
  		vertical-align: middle
  	.overlay-error
  		line-height: 30px
  		height: 30px
  		vertical-align: middle
  		color: red
  		margin-bottom: 5px
  	.overlay-actions
  		height: 55px
  		line-height: 50px
  		button
  			padding-left: 40px
  			padding-right: 40px

  .error-message
  	color: red
  	margin-bottom: 20px

  .lines
  	display: table
  	width: 100%

  .line
  	display: table-row
  	> div
  		display: table-cell
  		padding-left: 5px
  		padding-right: 5px
  		padding-bottom: 15px
  		height: 40px
  		line-height: 20px
  		vertical-align: top
  	.placement
  		> span
  			display: block
  			width: 40px
  			background: #ddd
  			line-height: 40px
  			vertical-align: middle
  			text-align: center
  	//.product
  	.depth
  		white-space: nowrap
  		text-align: right
  		line-height: 40px
  		vertical-align: middle
  	.in-stock
  		> a
  			color: white
  			font-weight: bold
  			font-size: 1.5rem
  			line-height: 40px
  			vertical-align: middle
  			&.in-stock
  				color: green
  			&.not-in-stock
  				color: red
  			i
  				vertical-align: -5%

  h1
  	margin: 0
  	margin-bottom: 20px
  	text-align: center

  .x-label
  	color: #888
  .x-value
  	font-size: 18px
  	font-weight: bold
  	text-transform: uppercase
  	margin-bottom: 10px
  .x-location
  	font-weight: normal
  	color: #888

  a.btn.btn-primary
  	color: black

  a.btn.btn-secondary
  	color: white
  	width: 90px

  h1 .subtitle
  	font-size: 18px

  a, a:hover, a:active, a:visited
  	text-decoration: none !important

  .v-tabs
  	margin-top: 20px
  	margin-bottom: 20px
</style>
